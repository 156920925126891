@mixin elevation-default {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

@mixin elevation-inset {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.04);
}

@mixin elevation-input-focus() {
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.08);
}

@mixin elevation($x-offset: 0px, $y-offset: 0px) {
  box-shadow: $x-offset 4 + $y-offset 12px rgba(0, 0, 0, 0.08),
    $x-offset $y-offset 4px rgba(0, 0, 0, 0.08);
}

@mixin elevation-2($x-offset: 0px, $y-offset: 0px) {
  box-shadow: $x-offset $y-offset 4px rgba(0, 0, 0, 0.08),
    0 8px 34px rgba(0, 0, 0, 0.12);
}

@mixin elevation-3 {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 0 34px rgba(0, 0, 0, 0.2);
}

@mixin elevation-3-drop-shadow {
  filter: drop-shadow(0 0 34px rgba(0, 0, 0, 0.2));
}

@mixin elevation-4 {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 0 34px rgba(0, 0, 0, 0.34);
}
