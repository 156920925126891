@import "../../../node_modules/@intemp/unijob-ui/scss/variables";
@import "../../../node_modules/@intemp/unijob-ui/scss/mixins/mixins";
@import "./styles/mixins/border-radius";
@import "./styles/mixins/pseudo-hover-outline";
@import "./styles/mixins/media";
@import "./styles/mixins/font-styles";
@import "./styles/mixins/elevation";
@import "./styles/mixins/forms";
@import "./styles/mixins/fabs";

@import "./styles/breakpoints";
@import "./styles/grid";
@import "./styles/spacers";

$font-weight-light: 300;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

$blur-amount: 3px;

// (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
$app-desktop-padding: 34px;
$app-mobile-padding: 20px;

$header-height: 60px;

$nav-width: 208px;
$nav-collapsed-width: 48px;
$nav-padding: 12px;

$container-max-width: 1142px;

$app-border-radius: 8px;
$app-border-radius-large: 20px;

$box-shadow-color-1: rgba(0, 0, 0, 0.08);

$slide-small-width: 440px;
$slide-medium-width: 660px;
$slide-large-width: 1046px;

// colors
$color-blue: #206eac;
$color-dark-blue: #003961;
$color-grey-blue: #86a2b5;
$color-light-blue: #e6ebed;
$color-grey: #f4f4f4;
$color-white: #ffffff;
$color-light-grey: #fcfcfc;
$color-dark-grey: #eeeeee;
$color-red: #eb5757;
$color-orange: #f2994a;
$color-green: #6fcf97;
$color-yellow: #fcc212;
$color-pink: #e6007e;
$color-violet: #281e78;

$color-icon-grey: #26292b;

$color-text-black: #1b1d1f;
$color-text-grey: #868788;

$color-validation-green: rgba(111, 207, 151, 0.56);
$color-validation-red: rgba($color-red, 0.75);

$overlay-color: rgba(#000, 0.75);
$overlay-color-lighter: rgba(#26292b, 0.7);
