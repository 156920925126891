@import "../variables.scss";

.modal-actions-row {
  width: 100%;
  display: flex;
  flex-grow: 1;

  & + .modal-actions-row {
    margin-top: 12px;
  }

  .modal-actions-col {
    display: flex;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
