@mixin hero-font-style {
  font-weight: 800;
  font-size: 88px;
  line-height: 120px;
}

@mixin h1-font-style {
  font-weight: 800;
  font-size: 38px;
  line-height: 52px;
}

@mixin h2-font-style {
  font-weight: 300;
  font-size: 32px;
  line-height: 44px;
  @include media-breakpoint-down(md) {
    font-size: 24px;
    line-height: 33px;
  }
}

@mixin h3-font-style {
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
}

@mixin h4-font-style {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}

@mixin h5-font-style {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}

@mixin h6-font-style {
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
}

@mixin label-text-m {
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
}

@mixin subtitle-font-style {
  @include h5-font-style();
  color: $color-text-grey;
}

@mixin small-subtitle-font-style {
  @include h6-font-style();
  color: $color-text-grey;
}

@mixin extra-small-subtitle-font-style {
  @include label-text-m();
  color: $color-text-grey;
}

@mixin default-font-style {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

@mixin default-italic-font-style {
  font-style: italic;
  font-size: 14px;
  line-height: 19px;
}

@mixin default-bold-font-style {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}

@mixin small-default-font-style {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

@mixin extra-small-default-font-style {
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
}

@mixin small-bold-font-style {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

@mixin button-text-m {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}

@mixin button-text-l {
  font-weight: 600;
  font-size: 18px;
  line-height: 1em;
  text-decoration: none;
}

@mixin hero-title {
  @include h2-font-style();
  color: $color-violet;
  margin-top: 0;
}

@mixin form-input-font-style {
  @include default-font-style();
}

@mixin form-label-font-style {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
}

@mixin inline-label-font-style {
  font-weight: 300;
  font-size: 8px;
  line-height: 12px;
  text-transform: uppercase;
}
