@import "~flatpickr/dist/flatpickr.min.css";
@import "../variables.scss";

$flatpickr-grey: #e6e6e6;

.flatpickr-day.selected .day-with-approvable-data {
  background: $color-white;
}

.day-with-approvable-data {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 150px;
  bottom: 4px;
  left: calc(50% - 1.5px);
  content: " ";
  display: block;
  background: $color-violet;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  text-decoration: line-through;
  color: rgba(57, 57, 57, 0.3);
}

.flatpickr-wrapper {
  display: block;
}

.flatpickr-calendar.open {
  z-index: 999999;
}

.flatpickr-calendar {
  border-radius: $app-border-radius;
  @include elevation();
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  display: none;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: $color-violet;
  border-color: $color-violet;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 $flatpickr-grey;
}

.flatpickr-day.today {
  //background-color: $color-green;
  //border-color: $color-green;
  //color: $color-white;
}

//.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
//  background-color: $color-grey;
//  border-color: $color-grey;
//}
//
//.flatpickr-day.inRange {
//  box-shadow: -5px 0 0 $color-grey, 5px 0 0 $color-grey;
//}
