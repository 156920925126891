//Form borders
@mixin validBorder {
  margin: 0;
  border: 1px solid $color-validation-green;
}

@mixin validationBorder {
  margin: 2px;
}

@mixin invalidBorder {
  margin: 0;
  border: 1px solid $color-validation-red;
}

@mixin customCheckbox {
  display: block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: $color-grey;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
  transition: 0.1s background-color;

  &.checkbox-elevated {
    @include elevation();
  }

  &.theme-white {
    background-color: $color-white;
  }

  &.checked {
    background-color: $color-violet;
  }
}

@mixin input {
  display: block;
  padding: 10px 20px;
  width: 100%;
  background-color: $color-white;
  border: 1px solid #eeedf2;
  border-radius: 4px;
  &::placeholder {
    font-weight: 300;
  }
}

@mixin input-disabled {
  background-color: $color-grey;
  cursor: not-allowed;
}

@mixin formLabel {
  display: flex;
  margin-bottom: 4px;
  text-align: left;
  @include form-label-font-style();
}

@mixin noValidationBorder {
  border-color: #eeedf2 !important;
}
