button.input-group-start,
input.input-group-start {
  border-radius: 4px 0px 0px 4px;
}

button.input-group-middle,
input.input-group-middle {
  border-radius: 0;
}

button.input-group-end,
input.input-group-end {
  border-radius: 0px 4px 4px 0px;
}

button.outline.content-color-dark-grey.input-delete {
  &:hover {
    color: $text-error;
  }

  &[disabled]:hover,
  &[disabled]:active,
  &.disabled:hover {
    color: $color-text-grey;
  }
}

.commission-input-group {
  unijob-input {
    .uj-input-field-wrapper,
    .uj-input-field-wrapper input {
      height: 100%;
    }
    .uj-input-field-wrapper input {
      cursor: text;
    }
  }

  .uni-dropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
