.stroke-content:after {
  content: "";
  width: calc(100% - 40px);
  display: flex;
  position: absolute;
  border-bottom: 1px solid #858585;
  top: 50%;
  left: 20px;
}

.stroke-content.text-area:after {
  top: 20px;
}
