@import "../variables.scss";

.page-action-buttons {
  position: sticky;
  z-index: 10;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;

  @include media-nav-mobile {
    bottom: 12px;
  }

  * {
    pointer-events: all;
  }

  & > * {
    width: 250px;
    @include media-nav-mobile {
      width: 180px;
    }
  }
}

.page-content {
  // makes space for page action buttons
}
