@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables.scss";
@import "../../../node_modules/@intemp/unijob-ui/tailwind.scss";
@import "styles/forms.scss";
@import "styles/status-indicator";
@import "styles/page-head";
@import "styles/text-modifiers";
@import "styles/page-structure.scss";
@import "styles/modal.scss";
@import "styles/slides";
@import "styles/css-helpers";
@import "styles/card";
@import "styles/flatpickr-custom-style.scss";
@import "styles/croppie.scss";
@import "styles/overflow-ellipsis";
@import "../../../node_modules/@intemp/unijob-ui/scss/atoms/global.scss";
@import "styles/input-group";
@import "styles/stroke-content";
html,
button,
input,
textarea {
  color: $text;
  font-family: "Karla", sans-serif;
  @include default-font-style();
}

::selection {
  // background: $color-violet;
  background: $color-text-black;
  color: white;
}

::-moz-selection {
  background: $color-violet;
  color: white;
}

body {
  overflow-y: scroll;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;

  .scroll-spacer {
    overflow-y: scroll;
    float: right;
    height: 100vh;
    visibility: hidden;
    display: none;
  }

  &.scroll-lock,
  &.slide-scroll-lock {
    overflow-y: hidden;

    .scroll-spacer {
      display: block;
    }
  }
}
html {
  &::before {
    content: "";
    background: linear-gradient(
        248.66deg,
        rgba(255, 36, 156, 0.04) 0%,
        rgba(56, 42, 167, 0.04) 100%
      ),
      linear-gradient(
        248.66deg,
        rgba(230, 0, 126, 0.04) 0%,
        rgba(40, 30, 120, 0.04) 100%
      ),
      #ffffff;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
}

#customCroppie {
  .cr-slider-wrap {
    margin-right: 0 !important;
    margin-left: 0 !important;
    width: 95%;
    text-align: left;
  }

  .slider {
    -webkit-appearance: none;
    height: 5px;
    border-radius: $border-radius-round;
    background: linear-gradient(
      90deg,
      rgba(230, 0, 126, 1) 150%,
      rgba(224, 224, 224, 1) 150%
    );
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: $brand-accent;
    cursor: pointer;
  }

  .slider::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  .cr-slider {
    width: 95%;
    padding-top: $spacer-2;
  }
}

#modal-wrapper {
  z-index: 21;
}

*:focus {
  outline: none;
}

a:hover,
button:hover {
  cursor: pointer;
}

.text-link:hover {
  text-decoration: underline;
}

body {
  margin: 0;
  position: relative;
}

a {
  color: $text;

  &:hover,
  &:focus,
  &:visited {
    color: $text;
  }
}

.app-wrapper {
  min-width: 320px;
}

.app-content-container {
  position: relative;
  margin: 0 auto;

  > * > .header-row {
    position: sticky;
    // min 2 is needed for vacancies list
    z-index: 2;
    // prevents the border radius from being blurry
    border-top-left-radius: 32px;
    backdrop-filter: blur(4px);
    background: rgb(255, 255, 255, 0.94);

    display: flex;
    top: $header-height;
    padding: 12px 20px 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -12px;
    @include media-nav-desktop() {
      padding-top: 34px;
      margin-top: -34px;
    }

    > * {
      z-index: 0;
    }
    & + * {
      padding-top: 20px;
    }
  }
}

.stretch-col {
  display: flex;
  align-items: stretch;

  > * {
    flex-grow: 1;
  }
}

.hidden {
  display: none;
}

.modal-wrapper {
  z-index: 99;
}
