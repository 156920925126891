@import "../variables.scss";

.breadcrumb {
  display: flex;
  align-items: center;

  margin-top: -10px;

  @include media-nav-mobile() {
    margin-top: 15px;
  }

  &,
  a,
  a:hover,
  a:focus,
  a:visited {
    @include subtitle-font-style();
    font-weight: 300;
    display: flex;
    flex-wrap: nowrap;
    text-decoration: none;
    align-items: center;
  }

  & + .page-head {
    padding-top: 0;

    h1 {
      margin-top: 1px;
    }
  }
}

.page-head {
  display: flex;
  align-items: center;

  .page-head-inner {
    padding-bottom: 12px;
    @include media-nav-desktop() {
      display: flex;
      padding-bottom: 0;
    }

    .display-option {
      padding-top: 12px;
      @include media-breakpoint-up(sm) {
        padding-top: 0;
      }
    }
  }

  .page-head-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 50px);
    @include media-nav-mobile() {
      white-space: normal;
    }
  }

  h1 {
    @include h3-font-style();
    display: flex;
    align-items: center;
    margin-top: 12px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }

    a {
      display: flex;
      align-items: center;
      padding-right: 12px;
    }
  }
}

.display-option {
  @include small-subtitle-font-style();
  display: flex;
  position: relative;
  align-items: center;

  unijob-input {
    width: 100%;
    margin-right: -1px;

    transition: width 0.7s ease-in-out;
  }

  button {
    z-index: 1;
  }

  .display-option-toggle {
    color: $color-violet;
    display: flex;
    align-items: center;
    padding-left: 8px;
    text-decoration: none;
    cursor: pointer;
  }
}
