@import "../variables.scss";

input {
  &::placeholder {
    @include form-input-font-style();
  }

  &[disabled] {
    cursor: no-drop;
    color: $color-text-grey;
  }

  &[disabled]::placeholder {
    content: "-";
    color: $color-text-grey;
  }
}

.custom-text-input,
.input-wrap .custom-text-input {
  @include input();
  background-color: $color-white;
}

textarea.custom-textarea {
  @include input();
  background-color: $color-white;
  resize: none;
}

.button-long-text {
  @include media-nav-mobile() {
    display: none;
  }
}

.button-short-text {
  @include media-nav-desktop() {
    display: none;
  }
}

.custom-checkbox-wrapper {
  .native-checkbox {
    display: none;
  }

  .custom-checkbox-label {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    padding-left: 0;

    @include default-font-style();
  }

  .custom-checkbox {
    @include customCheckbox();
  }

  &.disabled .custom-checkbox {
    cursor: not-allowed;
  }
}

.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.spaced-form-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.input-wrap {
  display: block;
  margin-bottom: 12px;

  &.valid input {
    @include validBorder();
  }

  &.invalid input {
    @include invalidBorder();
  }

  label,
  .input-label {
    @include formLabel();
  }

  input {
    @include input();

    & + .field-invalid-hint {
      padding-top: 8px;
    }
  }

  .input-disabled {
    @include input-disabled();
  }
}

.field-invalid-hint {
  @include small-default-font-style();
  color: $color-red;
}

.file-preview {
  .text-preview {
    display: flex;
    align-items: center;

    .file-link {
      color: $color-violet;
      text-decoration: none;
    }

    .remove-icon {
      cursor: pointer;
    }
  }

  .image-preview {
    background-color: $color-white;
    border-radius: $app-border-radius;
    padding: 30px;
    @include elevation();
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  .text-preview + .image-preview {
    margin-top: 12px;
  }
}

.input-hint {
  @include small-default-font-style();
  color: $color-text-grey;
  padding-left: 8px;
}
