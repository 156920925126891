@import "../variables.scss";

.slide-wrapper .slide-header {
  @include h3-font-style();
  margin: 0;
  @include media-slides-mobile() {
    margin-left: 40px;
  }
}

.slide-container {
  max-width: 1072px;
  margin: 0 auto;
}

.slides-wrapper {
  .slide-box {
    border-radius: 0;
  }

  .slide-background {
    transition: 0.2s background-color;
    background-color: transparent;
  }

  &.is-in-focus {
    .slide-box {
      border-radius: $app-border-radius-large 0 0 $app-border-radius-large;
    }

    .slide-background {
      background-color: $overlay-color-lighter;
    }
  }
}

// slide blur
.slides-wrapper .slide-wrapper {
}

.slides-wrapper.is-in-focus .slide-wrapper {
}
