@import "../variables.scss";

.ratio-image-wrap {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: $color-grey;
  overflow: hidden;

  .ratio-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
