@import "../variables.scss";

.red-text {
  color: $color-red;
}

b,
strong {
  font-weight: 700;
}
