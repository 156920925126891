@import "../variables.scss";

.status-indicator {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid $color-grey-blue;

  &.small {
    width: 8px;
    height: 8px;
  }

  &.filled {
    background-color: $color-grey-blue;

    &.green {
      background-color: $color-green;
    }

    &.yellow {
      background-color: $color-yellow;
    }

    &.red {
      background-color: $color-red;
    }
  }

  &.green {
    border: 2px solid $color-green;
  }

  &.yellow {
    border: 2px solid $color-yellow;
  }

  &.red {
    border: 2px solid $color-red;
  }
}
